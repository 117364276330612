import moment from 'moment'

export default class TournamentEmail {
  createdBy = null
  dtCreated = null
  emailList = []
  modifiedBy = null
  n = null
  sparkPostArchiveId = null
  tournamentEmail = null
  organizationEmail = null
  fullRequestJson = null
  opts = null

  constructor (dto, opts) {
    Object.assign(this, dto)
    // const dt = dto.tournamentEmail ? dto.tournamentEmail.startTime : dto.dtCreated
    // console.log(dt)
    this.startDate = moment(dto.dtCreated)
    this.opts = opts
    if (this.fullRequestJson) {
      const obj = JSON.parse(this.fullRequestJson)
      this.rawSparkpost = { sparkpost: obj.substitution_data }
      if (obj.recipients && obj.recipients.length === 1) {
        const r = obj.recipients[0]
        if (r && r.substitution_data) {
          Object.assign(this.rawSparkpost.sparkpost, r.substitution_data)
        }
      }
    }
  }

  get timestamp () {
    return this.startDate && this.startDate.format('X')
  }

  get dtString () {
    if (!this.startDate) return null
    if (moment().isSame(this.startDate, 'day')) return this.startDate.format('LT')
    return this.startDate.format('MMM D')
  }

  get email () {
    return this.tournamentEmail || this.organizationEmail || this.rawSparkpost
  }

  get subject () {
    return this.email && ((this.email.sparkpost && this.email.sparkpost.subject) || (this.email.isSMS && this.email.smsBody))
  }

  get sms () {
    return this.email && this.email.isSMS
  }

  get sentAddresses () {
    return [...new Set(this.emailList.map(m => m.Email))]
  }

  get sentTeamIds () {
    return [...new Set(this.emailList.filter(f => f.TeamId).map(m => m.TeamId))]
  }

  get sentDetails () {
    return this.sentTeamIds.map(m => {
      return {
        id: m,
        emails: this.emailList.filter(f => f.TeamId === m)
      }
    })
  }

  get body () {
    return this.email && this.email.isSMS ? this.email.smsBody : this.email.sparkpost.paragraph
  }

  get closing () {
    return this.email && !this.email.isSMS && this.email.sparkpost.closing
  }

  get signature () {
    return this.email && !this.email.isSMS && this.email.sparkpost.signature
  }

  get recipientSummary () {
    if (!this.emailList.length) return null
    const name = this.emailList[0].Name || this.emailList[0].Email
    const other = this.emailList.length > 1 ? ` and ${this.emailList.length - 1} other${this.emailList.length - 1 > 1 ? '' : 's'}` : ''
    return `${name}${other}`
  }

  get recipients () {
    if (this.tournamentEmail && this.tournamentEmail.recipients) {
      return this.tournamentEmail.recipients
    }
    return this.emailList.map(m => `${m.name}<${m.Email}>`)
  }

  get from () {
    return this.email && this.email.sparkpost && this.email.sparkpost.fromName
  }

  get chips () {
    const chips = []
    const o = this.organizationEmail
    if (o && this.opts) {
      const a = this.opts.ageTypeOptions.find(f => f.id === o.age)
      a && chips.push(a.name)
      if (o.age > 0) {
        const d = this.opts.divisionOptions.filter(f => o.divisionIds.includes(f.id))
        d.length && chips.push(...d.map(m => m.name))

        const g = this.opts.genderOptions.find(f => f.id === o.gender)
        g && chips.push(g.name)
      }
    }

    return chips
  }
}
