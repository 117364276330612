<template>
  <organization-email-box :username="username"></organization-email-box>
</template>

<script>
import OrganizationEmailBox from '@/components/Notifications/OrganizationEmailBox'

export default {
  props: ['username'],
  components: {
    OrganizationEmailBox
  }
}
</script>
