<template>
  <div>
    <v-tooltip left>
      <span>Compose</span>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          @click.stop="dialog = true"
          color="color3 color3Text--text"
          class="ma-0"
          fab
          :bottom ="fixed"
          :right="fixed"
          :fixed="fixed"
          :style="fixed ? `margin-right: 60px` : ''"
        >
          <v-icon>fas fa-edit</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      scrollable
      fullscreen
      hide-overlay transition="dialog-bottom-transition"
    >
      <v-card light>
        <div>
          <v-toolbar color="color1 color1Text--text">
            <v-btn color="color1Text" text icon>
              <v-icon @click.stop="cancel">fas fa-times</v-icon>
            </v-btn>
            <v-toolbar-title>Compose</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="color1Text"
              text
              :disabled="!valid"
              :loading="loading"
            >Send</v-btn>
          </v-toolbar>
        </div>
        <v-card-text>
          <v-container>
            <v-row dense>
              <!-- Recipients -->
              <v-col cols="12">
                <v-card color="grey lighten-3">
                  <v-card-title class="subheading" style="min-height: 68px">
                    <b>Recipients</b>
                    <v-chip v-if="selectedPlayers.length" color="color3Text color3--text">
                      <v-avatar
                        color="color3"
                        class="color3Text--text"
                        size="36"
                        v-text="selectedPlayers.length"
                      ></v-avatar>
                      Players
                    </v-chip>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text v-if="options">
                    <v-row dense>
                      <v-col cols="12" class="pb-0">
                        <div class="body-2">Please select an age group:</div>
                        <v-select
                          label="Age Group"
                          :items="ages"
                          item-text="name"
                          item-value="id"
                          v-model="ageGroup"
                          color="color3"
                          item-color="color3"
                          solo
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-expand-transition>
                      <v-row v-if="ageGroup">
                        <v-col cols="12" class="py-0">
                          <div class="body-2">Please select the division(s) you'd like to include:</div>
                          <v-select
                            ref="select1"
                            :items="divisions"
                            item-text="name"
                            item-value="id"
                            v-model="divs"
                            label="Age Group"
                            color="color3"
                            item-color="color3"
                            solo
                            multiple
                            chips deletable-chips
                          >
                            <v-list-item
                              slot="prepend-item"
                              ripple
                              @click="toggleDivision"
                              :color="selectIcon.color"
                            >
                              <v-list-item-action>
                                <v-icon :color="selectIcon.color">{{selectIcon.name}}</v-icon>
                              </v-list-item-action>
                              <v-list-item-title>Select All</v-list-item-title>
                            </v-list-item>
                            <v-divider
                              slot="prepend-item"
                              class="mt-2"
                            ></v-divider>
                          </v-select>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <div class="body-2">Please select a gender:</div>
                          <v-select
                            label="Age Group"
                            :items="genders"
                            item-text="name"
                            item-value="id"
                            v-model="gender"
                            color="color3"
                            item-color="color3"
                            solo
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-expand-transition>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Message -->
              <v-col cols="12">
                <v-card color="grey lighten-3">
                  <v-card-title class="subheading">
                    <b>Message</b>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <!-- Subject/Body -->
                    <v-row dense>
                      <v-col cols="12" class="pa-0">
                        <div class="body-2">Subject:</div>
                        <v-text-field
                          label="Subject*"
                          v-model="sparkpost.subject"
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="pa-0">
                        <div class="body-2">Body:</div>
                        <html-editor v-model="sparkpost.paragraph[0].text" :no-header="true" :no-card="true"></html-editor>
                      </v-col>
                    </v-row>
                    <!-- Closing -->
                    <v-row dense class="pt-4">
                      <v-col cols="12" sm="6" md="3">
                        <div class="body-2">Closing:</div>
                        <v-text-field
                          label="Closing*"
                          v-model="sparkpost.closing"
                          solo
                          persistent-hint
                          hint="i.e. Thank you, See you at the beach, Sincerely"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Signature -->
                    <v-row dense>
                      <v-col cols="12" sm="6" md="3">
                        <div class="body-2">Signature:</div>
                        <v-text-field
                          label="Signature*"
                          v-model="sparkpost.signature"
                          solo
                          persistent-hint
                          hint="Your name or you organizations name"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Buttons -->
                    <v-row dense justify="end">
                      <v-tooltip left>
                        <span>Send</span>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            color="color3 color3Text--text"
                            fab
                            bottom
                            right
                            fixed
                            :style="`z-index: 5`"
                            @click.stop="send"
                            :disabled="!valid"
                            :loading="loading"
                          >
                            <v-icon>fas fa-paper-plane</v-icon>
                          </v-btn>
                        </template>
                      </v-tooltip>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import Sparkpost from '@/classes/Sparkpost'
import HtmlEditor from '@/components/Utils/HtmlEditor.vue'

export default {
  props: ['active', 'fixed', 'username', 'options'],
  data () {
    return {
      dialog: false,
      selectedDivisionIds: [],
      includeWithdrawn: false,
      sparkpost: new Sparkpost(),
      loading: false,
      sent: false,
      selectedPlayers: false,
      ageGroup: 0,
      divs: [],
      gender: 0
    }
  },
  computed: {
    ...mapGetters(['user', 'getPageInfo']),
    valid () {
      return this.sparkpost.valid && (this.ageGroup === 0 || this.divs.length > 0)
    },
    ages () {
      return this.options && this.options.ageTypeOptions
    },
    genders () {
      return this.options && this.options.genderOptions.filter(f => !f.ageTypeId || f.ageTypeId === this.ageGroup)
    },
    divisions () {
      const base = []
      if (this.options && this.ageGroup) base.push(...this.options.divisionOptions.filter(f => f.ageTypeId === this.ageGroup))
      return base
    },
    dto () {
      return {
        username: this.username,
        age: this.ageGroup,
        gender: this.gender,
        divisionIds: this.divs,
        sparkpost: this.sparkpost,
        when: null
      }
    },
    organization () {
      return this.getPageInfo(this.username)
    },
    selectIcon () {
      if (this.allDivisions) return { name: 'fas fa-check-square', color: 'color3' }
      if (this.someDivisions) return { name: 'fas fa-minus-square', color: 'color3' }
      return { name: 'far fa-square', color: '' }
    },
    allDivisions () {
      return this.divs.length === this.divisions.length
    },
    someDivisions () {
      return this.divs.length > 0 && !this.allDivisions
    },
    selectedDivisions () {
      return this.divisions.filter(d =>
        this.divs.includes(d.id)
      )
    }
  },
  methods: {
    toggleDivision () {
      this.$nextTick(() => {
        if (this.allDivisions) {
          this.divs = []
        } else {
          this.divs = this.divisions.map(d => d.id)
          if (this.$refs.select1) this.$refs.select1.blur()
        }
      })
    },
    init () {
      this.ageGroup = 0
      this.gender = 0
      this.divs = []
      this.sparkpost = new Sparkpost()
      this.sparkpost.closing = 'See ya at the beach,'
      this.sparkpost.signature = this.user.fullname
      this.sparkpost.preheader = `Update from ${this.organization.name}`
    },
    send () {
      this.loading = true
      this.$VBL.organization.notifications.post(this.dto)
        .then(r => {
          this.sent = true
          this.$emit('sent')
          this.dialog = false
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    cancel () {
      this.dialog = false
    }
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.init()
      }
    }
  },
  components: {
    HtmlEditor
  },
  mounted () {
  }
}
</script>

<style>

</style>
