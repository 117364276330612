<template>
  <v-card>
    <loading-bar :value="loading"/>
    <v-container>
      <v-window v-model="window" touchless>
        <!-- SENT BOX -->
        <v-window-item :value="0">
          <v-row dense justify="space-between">
              <span class="title">Notification Center</span>
              <v-btn color="color3" text icon @click.stop="load" :loading="loading">
                <v-icon>fas fa-sync-alt</v-icon>
              </v-btn>
          </v-row>
          <v-list two-line v-if="emails.length">
            <template v-for="email in emails">
              <v-divider :key="`divider_${email.n}`"></v-divider>
              <v-list-item :key="`tile_${email.n}`" @click.stop="selectedEmail = email">
                <v-list-item-content>
                  <v-list-item-title>{{email.subject}}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip small v-for="(chip, i) in email.chips" :key="i">{{chip}}</v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="email.status === 'Sent' && !(email.recipientSummary && email.recipientSummary.length)">
                    Not Sent - No Players meet your criteria'
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-else>
                    {{email.status}}
                    <v-progress-circular
                      v-if="email.status === 'Sending'"
                      :size="20"
                      color="success"
                      indeterminate></v-progress-circular>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>{{ email.startDate.calendar() }}</v-list-item-action-text>
                  <v-icon></v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
          <v-row dense v-else>
            <v-col cols="12" class="title text-center">
              You do not have any sent messages.
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="text-right" cols="12" >
              <organization-email-compose @sent="load" :username="username" :options="options"></organization-email-compose>
            </v-col>
          </v-row>
        </v-window-item>
        <!-- REVIEW -->
        <v-window-item :value="1">
          <v-row dense justify="space-between">
              <v-btn color="color3" text icon @click.stop="selectedEmail = null" class="ma-0">
                <v-icon>fas fa-angle-left</v-icon>
              </v-btn>
          </v-row>
          <template v-if="selectedEmail">
            <v-divider></v-divider>
            <v-row dense>
              <v-col cols="12" class="subheading">
                {{selectedEmail.subject}}
              </v-col>
              <v-col cols="12" class="caption grey--text">
                {{selectedEmail.startDate.format('LLLL')}}
              </v-col>
              <v-col cols="12" class="caption grey--text">
                From: {{selectedEmail.from}}
              </v-col>
              <v-col cols="6" class="caption grey--text">
                To: {{selectedEmail.recipientSummary}}
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn
                  color="color3"
                  text
                  class="xsmall ma-0 pa-0"
                  @click="showDetails = !showDetails"
                >{{showDetails ? 'hide' : 'details'}}</v-btn>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-row dense v-if="showDetails">
                <v-col cols="12" class="caption grey--text">
                  Created By: {{selectedEmail.createdBy}}
                </v-col>
                <v-col cols="12">
                  <v-chip class="ma-1" small v-for="obj in selectedEmail.emailList" :key="obj.email">{{obj.Name ? `${obj.Name} &lt;${obj.Email}>` : `${obj.Email}`}}</v-chip>
                </v-col>
              </v-row>
            </v-expand-transition>
            <v-divider></v-divider>
            <v-row dense class="pt-4">
              <v-col cols="12" v-for="(p, i) in selectedEmail.body" :key="i" v-html="p.text"></v-col>
              <v-col cols="12" v-if="selectedEmail.signature !== 'none'">
                <div>{{selectedEmail.closing}}</div>
                <div>{{selectedEmail.signature}}</div>
              </v-col>
            </v-row>
          </template>
        </v-window-item>
      </v-window>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import TournamentEmail from '@/classes/TournamentEmail'
import OrganizationEmailCompose from '@/components/Notifications/OrganizationEmailCompose'
import firstBy from 'thenby'

export default {
  props: ['active', 'username'],
  data () {
    return {
      loading: false,
      loadingOptions: false,
      sentEmails: [],
      selectedEmail: null,
      window: 0,
      showDetails: false,
      options: null,
      timeout: null
    }
  },
  computed: {
    ...mapGetters(['user', 'getTeam']),
    a () {
      return this.emails.map(m => {
        return {
          recipientSummary: m.recipientSummary,
          emailList: m.emailList,
          sentTeamIds: m.sentTeamIds
        }
      })
    },
    emails () {
      if (this.sentEmails && this.options) {
        const list = this.sentEmails.map(m => new TournamentEmail(m, this.options))
        list.sort(firstBy('timestamp', -1))
        return list
      }
      return []
    },
    statuses () {
      return this.sentEmails.map(m => m.status)
    },
    sending () {
      return this.statuses.includes('Sending')
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$VBL.organization.notifications.get(this.username)
        .then(r => { this.setMail(r.data) })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    loadOptions () {
      this.loadingOptions = true
      this.$http.get(`${window.location.origin}/static/json/TournamentOptions.json`)
        .then(r => {
          this.options = r.data
          this.options.ageTypeOptions.unshift({ id: 0, name: 'Everyone' })
          this.options.genderOptions.unshift({ id: 0, name: 'All' })
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loadingOptions = false
        })
    },
    getDivisionName (id) {
      const d = this.tournament.divisions.find(f => f.id === id)
      return d ? d.name : null
    },
    setMail (mail) {
      this.sentEmails = mail
      this.$nextTick(() => {
        if (this.sending) {
          this.timeout = setTimeout(this.load, 5000)
        }
      })
    }
  },
  watch: {
    active: function (val) {
      if (val) {
        this.load()
        this.loadOptions()
      }
    },
    selectedEmail: function (val) {
      this.window = val ? 1 : 0
    }
  },
  mounted () {
    this.load()
    this.loadOptions()
  },
  components: {
    OrganizationEmailCompose
  },
  destroyed () {
    if (this.timeout) clearTimeout(this.timeout)
  }
}
</script>

<style>

</style>
